import React from 'react'
import { Container, Grid } from '@material-ui/core'

export default function Onecol(props) {

    const Description = [
        `${props.description}`
    ]

    return (
        <Container>
            <Grid container className="onecol">
                <Grid xs={12} className="py-40 text-center">
                    <h3>{props.title}</h3>
                    <p className="md:w-3/4 mx-auto">{Description}</p>
                </Grid>
            </Grid>      
        </Container>
    )
}

import React from 'react'
import { Container } from '@material-ui/core'

export default function Banner(props) {

    return (
        <div className={`white-text ${props.small ? 'skate-parallax mb-40' : 'text-center hero-banner'}`} style={{ backgroundImage: `url(${props.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="blue-gradient">
                <Container className={`${props.small ? 'py-20 md:p-56 md:text-left' : 'py-48'}`}>
                    {props.small ?
                    <> 
                        <h3>{props.title}</h3>
                        <h6 className="my-6">{props.description}</h6>
                    </>
                    :                    
                    <> 
                        <h1>{props.title}</h1>
                        <h5 className="my-12">{props.description}</h5>
                    </>
                    }
                </Container>
            </div>
        </div>
    )
}
import React from 'react'
import { Container, Button } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ImageCTA(props) {
    return (
        <Container className="image-cta light-shadow mb-40">
            <div className="icta-container flex justify-between items-center">
                <div className="icta-text">
                    <h5>{props.title}</h5>
                </div>
                <div className="icta-image">
                    <LazyLoadImage
                        src={props.image}
                        effect="blur"
                        width="200px"
                    />
                </div>
                <div className="icta-button">
                    <Button variant="outlined" color="secondary" href={props.link}>{props.linkText}</Button>
                </div>
            </div>
        </Container>  
    )
}
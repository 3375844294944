import React from 'react'
import { Link } from 'gatsby'
import { Container, Button } from '@material-ui/core'

export default function ButtonCTA(props) {
    return (
        <Container className="button-cta light-shadow mb-40">
        <div className="bcta-container flex flex-col justify-between items-center py-40">
          <div className="bcta-text">
            <h4>There are infinite possibilities when you work with Pros <span className="blue">Marketing</span></h4>
          </div>
          <div className="bcta-buttons mt-20">
            <Link to="/get-quote"><Button variant="outlined" color="secondary">get a quote</Button></Link>
            <Link to="/contact-us"><Button variant="outlined" color="primary">get in touch</Button></Link>
          </div>
        </div>
      </Container>  
    )
}